<template>
    <div>
        <el-table stripe v-loading="loading" :data="dataList" >
            <el-table-column align="center" label="代理编号" prop="agentNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column align="center" label="代理名称" prop="userName" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column label="代理商" prop="agentNo" min-width="140" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.agentNo}}<span @click="showAgentLevel(scope.row)" style="color:rgb(2,167,240); cursor: pointer;">(代理层级)</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="乐刷编号" prop="lsAgentId" min-width="120" :show-overflow-tooltip="true"/>
            <!-- <el-table-column align="center" label="等级" prop="agentLevel" min-width="120" :show-overflow-tooltip="true"/> -->
            <!-- <el-table-column align="center" label="层级关系" prop="" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <a @click="showDialog(scope.row)">查看</a>
                </template>
            </el-table-column> -->
            <el-table-column align="center" label="联系人手机号" prop="contactMobileNo" min-width="120" :show-overflow-tooltip="true"/>
            <el-table-column label="上级代理商" prop="parentAgentNo" min-width="200" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    {{scope.row.parentAgentNo}} - {{scope.row.parentUserName}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="新增时间" prop="createTime" min-width="180" :show-overflow-tooltip="true"/>
            <el-table-column align="center" label="结算状态" prop="" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.setterStatus==1?'success':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.setterStatus">{{scope.row.setterStatus | setterStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" label="交易分润代付开关" prop="" min-width="140" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.profitSwitch"
                        active-value="1"
                        inactive-value="0"
                        @change="profitSwitchChang(scope.row)"
                        >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column align="center" label="笔数分润代付开关" prop="" min-width="140" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.settleProfitSwitch"
                        active-value="1"
                        inactive-value="0"
                        @change="settleProfitSwitchChange(scope.row)"
                        >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column align="center" label="返现开关" prop="" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.cashbackSwitch"
                        active-value="1"
                        inactive-value="0"
                        @change="cashbackSwitchChang(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column align="center" label="审核状态" prop="" min-width="120" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                    <el-button :type="scope.row.status==1?'danger':scope.row.status==4?'success':'primary'" 
                    plain size="mini" class="button-status" v-if="scope.row.status">{{scope.row.status | userStatusFM}}</el-button>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作" class-name="small-padding fixed-width" width="340" fixed="right">
                <template slot-scope="scope">
					<el-button size="mini" type="text" @click="handleReset(scope.row)" v-permission="'AGENT_INFO_RESET_PASSWORD'">
                        <i class="el-icon-lock"></i>
                        重置密码
                    </el-button>
					<el-button size="mini" type="text" @click="handleUpdateBenefit(scope.row)" v-permission="'AGENT_INFO_UPDATE'">
                        <i class="el-icon-edit"></i>
                        修改分润
                    </el-button>
					<el-button size="mini" type="text" @click="handleUpdateCashBack(scope.row)" v-permission="'AGENT_INFO_UPDATE'">
                        <i class="el-icon-edit"></i>
                        修改返现
                    </el-button>
					<el-button size="mini" type="text" v-if="scope.row.status == '3'" @click="handleUpdate(scope.row)" v-permission="'AGENT_INFO_UPDATE'">
                        <i class="el-icon-edit"></i>
                        补充资料
                    </el-button>
					<el-button size="mini" type="text" @click="handleDetail(scope.row)">
                        <i class="el-icon-edit-outline"></i>
                        详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <Pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList"/>
        <el-dialog
            title="层级关系"
            :visible.sync="centerDialogVisible"
            width="50%"
            center>
            <template>
            <el-table stripe border :data="levelList" border style="width: 100%">
                <el-table-column label="身份">
                    <template slot-scope="scope">
                        {{scope.row.userRole | userRoleFM}}
                    </template>
                </el-table-column>
                <el-table-column prop="agentLevel" label="级别">
                    <template slot-scope="scope">
                        {{scope.row.userRole == 'ORG' ? scope.row.orgLevel : scope.row.agentLevel}}
                    </template>
                </el-table-column>
                <el-table-column prop="userName" label="名称" />
                <el-table-column prop="agentNo" label="编号" />
            </el-table>
            </template>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :visible.sync="updateBenefitShow"
            width="1100px">
                <BenefitConfig @change="closeDialog" :parentAgentNo="parentAgentNo" :show="updateBenefitShow" :agentNo="agentNo" :agentName="agentName" type="agent" :isUpdate="true"></BenefitConfig>
        </el-dialog>
        <el-dialog
            :visible.sync="updateCashBackShow"
            width="1100px">
                <CashBackConfig @change="closeDialog2" :parentAgentNo="parentAgentNo" :show="updateCashBackShow" :agentNo="agentNo" :agentName="agentName" type="agent" :isUpdate="true"></CashBackConfig>
        </el-dialog>
    </div>
</template>

<script>
    import {AgentApi} from '@/api';
    import BenefitConfig from '@/components/agentUpdateBase/benefitConfig'
    import CashBackConfig from '@/components/agentUpdateBase/cashBackConfig'
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: function () {
                    return {}
                }
            },
            queryParams: {
                type: Object,
                default: function () {
                    return {}
                }
            },
        },
        components:{
            BenefitConfig,
            CashBackConfig
        },
        data() {
            return {
                pageNo: 1,
                pageSize: 10,
                loading: false,
                total: 0,
                dataList: [],
                selectRow: {},
                showCustomForm: false,
                isAdd: false,
                centerDialogVisible: false,
                levelList:[],
                updateBenefitShow: false,
                updateCashBackShow: false,
                parentAgentNo: '',
                agentName: '',
                agentNo: 0
            }
        },
        mounted() {
        },
        watch: {
            queryParams: function () {
                this.pageNo = 1;
                this.getList();
            },
        },
        methods: {
            async getList() {
                this.loading = true;
                let result = await AgentApi.getAgentlist(this.pageNo, this.pageSize, this.queryParams);
                this.loading = false;
                this.total = result.count || 0;
                this.dataList = result.data || [];
            },
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.getList();
            },
            handleStatusChange(row) {
                let text = row.status === "DISABLE" ? "禁用" : "启用";
                this.$confirm("是否确定"+text+"?","警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await AgentApi.agentInfo.edit(row.agentNo, {
                        state: row.state
                    });
                    if (result.success) {
                        this.getList();
                    }
                }).catch(() => this.getList());
            },
            async handleUpdate(row) {
                this.$router.push({
                    name:'agentUpdate',
                    query:{
                        agentNo: row.agentNo,
                        contactMobileNo: row.contactMobileNo,
                        userType: row.userType
                    }
                })
            },
            handleReset(row){
                this.$confirm("是否确定重置业务用户（包含机构/代理商）编号为"+row.agentNo+"的密码?",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    let result = await AgentApi.resetPassword(row.agentNo);
                    if (result.success) {
                        this.$alert(result.data, {
                        confirmButtonText: '确定',
                        });
                    }
                });
            },
            async showDialog(row){
                let result = await AgentApi.getAgentLevel(row.agentNo);
                if(result.success){
                    this.centerDialogVisible = true
                    this.levelList = result.data
                }
            },
            handleDetail(row){
                this.$router.push({
                    name:'agentDetail',
                    query:{
                        agentNo: row.agentNo
                    }
                })
            },
            handleUpdateBenefit(row){
                this.updateBenefitShow = true
                this.parentAgentNo = row.parentAgentNo
                this.agentName = row.userName
                this.agentNo = row.agentNo
            },
            handleUpdateCashBack(row){
                this.updateCashBackShow = true
                this.parentAgentNo = row.parentAgentNo
                this.agentName = row.userName
                this.agentNo = row.agentNo
            },
            closeDialog(e){
                this.updateBenefitShow = false
            },
            closeDialog2(e){
                this.updateCashBackShow = false
            },
            async profitSwitchChang(item){
                let result = await AgentApi.profitSwitch(item.agentNo,item.profitSwitch)
                if(result.success){
                    this.Message.success('修改成功!')
                }
            },
            async settleProfitSwitchChange(item){
                let result = await AgentApi.settleProfitSwitch(item.agentNo,item.settleProfitSwitch)
                if(result.success){
                    this.Message.success('修改成功!')
                }
            },
            async cashbackSwitchChang(item){
                let result = await AgentApi.cashbackSwitch(item.agentNo,item.cashbackSwitch)
                if(result.success){
                    this.Message.success('修改成功!')
                }
            }
        }
    };
</script>

<style scoped>

</style>
